@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  /* font-family: 'Almarai'; */
  font-weight: 500;
  font-size: 13pt;
  line-height: 40px;
  color: inherit;
  text-decoration: none;
  text-underline-offset: 10px;
  box-sizing: border-box;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

body{
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
  background-image: url('bg/GPSPattern.svg');
  background-size: 17rem;
  background-repeat: repeat;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-thumb {
    width: 0px;
    -webkit-appearance: none;
    appearance: none;
    height: 0px;
    cursor: ew-resize;
    background: #FFF;
    box-shadow: -405px 0 0 400px #ee3135;
    border-radius: 50%;
    border-radius: 100%;
  }
}


/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/Almarai-Light.woff) format("woff");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/Almarai-Regular.woff) format("woff");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/Almarai-Bold.woff) format("woff");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(fonts/Almarai-ExtraBold.woff) format("woff");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #EA2127;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}